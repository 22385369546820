var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "grid grid-cols-1 lg:grid-cols-2 login" },
    [
      _c("static-text", { attrs: { advisor: true } }),
      _c(
        "div",
        {
          staticClass:
            "m-0 w-full item-direction md:my-16 md:mx-auto md:w-3/5 lg:mx-0 lg:w-4/5 xl:max-w-md"
        },
        [
          _c(
            "custom-card",
            { attrs: { title: "Credenciais de acesso", subtitle: "" } },
            [
              _c("ValidationObserver", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var invalid = ref.invalid
                      return [
                        _c(
                          "form",
                          [
                            _c("ValidationProvider", {
                              attrs: {
                                name: "E-mail",
                                rules: "required|email"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("vs-input", {
                                          staticClass: "w-full",
                                          attrs: {
                                            size: "large",
                                            label: "E-mail"
                                          },
                                          model: {
                                            value: _vm.email,
                                            callback: function($$v) {
                                              _vm.email = $$v
                                            },
                                            expression: "email"
                                          }
                                        }),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: errors[0],
                                                expression: "errors[0]"
                                              }
                                            ],
                                            staticClass: "text-danger text-sm"
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(errors[0]) +
                                                "\n            "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("ValidationProvider", {
                              attrs: { name: "Senha", rules: "required|min:6" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("vs-input", {
                                          staticClass: "w-full mt-8",
                                          attrs: {
                                            type: "password",
                                            size: "large",
                                            label: "Senha"
                                          },
                                          on: {
                                            keyup: function($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              ) {
                                                return null
                                              }
                                              return _vm.loadLogin()
                                            }
                                          },
                                          model: {
                                            value: _vm.password,
                                            callback: function($$v) {
                                              _vm.password = $$v
                                            },
                                            expression: "password"
                                          }
                                        }),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: errors[0],
                                                expression: "errors[0]"
                                              }
                                            ],
                                            staticClass: "text-danger text-sm"
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(errors[0]) +
                                                "\n            "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "flex flex-wrap justify-end my-5"
                              },
                              [
                                _c(
                                  "router-link",
                                  { attrs: { to: "/recuperacao-da-senha" } },
                                  [_c("b", [_vm._v("Esqueceu a senha?")])]
                                )
                              ],
                              1
                            ),
                            _c(
                              "vs-button",
                              {
                                staticClass: "w-full mt-8 uppercase",
                                attrs: { disabled: invalid, size: "large" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.loadLogin()
                                  }
                                }
                              },
                              [_vm._v("Entrar")]
                            ),
                            !_vm.Mapfre
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex flex-wrap justify-center mt-12"
                                  },
                                  [
                                    _vm._v(
                                      "\n            Não tem acesso?\n            "
                                    ),
                                    _c(
                                      "router-link",
                                      { attrs: { to: "/iniciar-cadastro" } },
                                      [
                                        _c("b", { staticClass: "pl-2" }, [
                                          _vm._v("Cadastrar-se")
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("soliciticitar-token", {
        attrs: {
          dados: { email: _vm.email, password: _vm.password },
          showModal: _vm.selectAuthTypeModal
        },
        on: {
          closeModal: function($event) {
            _vm.selectAuthTypeModal = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }